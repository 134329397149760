<template>
  <div style="line-height: 0.375rem !important;">
    <v-row no-gutters v-if="workflowLoading">
      <v-col v-for="n in 5" :key="n" cols="12">
        <v-skeleton-loader class="mx-auto"  type="list-item-avatar"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-list flat class="tile--list pt-0" v-else-if="listOfWorkflows.length > 0 && !workflowLoading" dense style="max-height:365px; overflow: auto;">
      <v-list-item-group active-class="primary--text">
        <template v-for="(document, index) in listOfWorkflows">
          <v-list-item v-if="!$vuetify.breakpoint.xs" style="width: 100%" :key="`${index}_name`">
            <v-list-item-content style="width: 80%">
              <v-list-item-subtitle :title="document.workflowsubject" class="text--primary" v-text="document.workflowsubject"></v-list-item-subtitle>
              <v-btn v-if="document.taskid" icon x-small class="mr-3" @click.stop="redirectToTaskModule(document)">
                <v-icon size="20"> mdi-open-in-new </v-icon>
              </v-btn>
            </v-list-item-content>
            <div style="width: 18%">
              <v-chip :color="getStatusColor(document.workflowstatus)" small outlined v-if="document.workflowstatus">{{ document.workflowstatus | firstLetterCaptialize }}</v-chip>
            </div>
            <v-list-item-action style="width: 20%" class="flex-row">
              <!-- <v-btn icon x-small class="mr-3" @click.stop="redirectToWorkflow(document)"><v-icon size="20">mdi-open-in-new</v-icon></v-btn> -->
              <v-list-item-action-text class="mb-2">{{ $formatter.getHumanzieTime(document.createdat, $i18n.locale === 'no' ? 'nb' : $i18n.locale) }}</v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-else style="width: 100%" :key="`${index}_name`">
            <v-list-item-content style="width: 80%">
              <v-list-item-subtitle :title="document.workflowsubject" class="text--primary" v-text="document.workflowsubject"></v-list-item-subtitle>
            <div style="width: 18%">
              <v-chip :color="getStatusColor(document.workflowstatus)" x-small outlined v-if="document.workflowstatus">{{ document.workflowstatus | firstLetterCaptialize }}</v-chip>
            </div>
            </v-list-item-content>
            <v-list-item-action style="width: 20%">
              <!-- <v-btn icon x-small class="mr-3" @click.stop="redirectToWorkflow(document)"><v-icon size="20">mdi-open-in-new</v-icon></v-btn> -->
              <v-btn v-if="document.taskid" icon x-small class="mr-3" @click.stop="redirectToTaskModule(document)"><v-icon size="20"> mdi-open-in-new </v-icon></v-btn>
              <v-list-item-action-text class="mb-2">{{ $formatter.getHumanzieTime(document.createdat, $i18n.locale === 'no' ? 'nb' : $i18n.locale) }}</v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>
          <v-divider v-if="index + 1 < listOfWorkflows.length" :key="index"></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
    <v-alert color="info" dark icon="mdi-cloud-alert" border="left" prominent outlined v-else>
      {{ $t('noActiveWorkflow') }}
    </v-alert>
  </div>
</template>
<script>
export default {
  props: ['listOfWorkflows', 'workflowLoading'],
  methods: {
    redirectToWorkflow (item) {
      this.$router.push(`/module/${item.module}/${item.moduleid}/view/${item.moduleobjid}?docid=${item.routedocorfolderid}&workflowid=${item.workflowid}`)
    },
    redirectToTaskModule (item) {
      this.$router.push(`/module/Task/${item.taskid}/view/${item.taskobjid}?docid=${item.routedocorfolderid}&workflowid=${item.workflowid}`)
    },
    getStatusColor (status) {
      switch (status) {
        case this.STATUS_CREATED: return 'indigo accent-4'
        case this.STATUS_INPROGRESS: return 'blue lighten-1'
        case this.STATUS_COMPLETED: return 'success'
        case this.STATUS_CANCELLED: return 'error'
        case this.STATUS_ONHOLD: return 'orange'
        default: return 'primary'
      }
    }
  }
}
</script>

<style>
</style>
